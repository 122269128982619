import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import SectionTitle from '../../components/Shared/SectionTitle';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useFormspark } from '@formspark/use-formspark';
import { usePricingStore } from '../../components/Shared/store';
import Paypalbuton from './PaymentButton';

const getRandomInt = (max) => Math.floor(Math.random() * max);
import classic from "../../assets/images/smash-pass/pass.jpeg";

const ContactUs = ({ clientID }) => {
  const [succeMsg, setsucceMsg] = useState(false);
  const [onClickPaiement , setOnclickPaiement] = useState(false)
  const [submit2] = useFormspark({ formId: 'h820ZN6u' }); // confirmed
  const [submit] = useFormspark({ formId: 'qMU4XccKq' }); // Not confirmed
  const { selectedPlan, selectPlan, pricings } = usePricingStore(
    (state) => state,
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      phone: '',
      role: '',
      comments: '',
      paymentType: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Name'),
      email: Yup.string().email().required('Email is required'),
      phone: Yup.string().required('Phone is required'),
    }),
    onSubmit: (values) => {
      setOnclickPaiement(true)
      submit({ ...values, id: 'NOT PAID', formule: selectedPlan.title, prix: selectedPlan.price, alreadyPaid: "Non" });
    },
  });


  const validate = () => {
      submit2({ ...validation.values, id: 'SmashOrPass-' + getRandomInt(100000), formule: selectedPlan.title, prix: selectedPlan.price, alreadyPaid: "Oui" });
      setsucceMsg(true);
  }

  const sendMail = () => {
    window.location.href = 'mailto:kevinkouchougtchinda@gmail.com';
  };

  const callNumber = () => {
    window.location.href = 'tel:+4917687306753';
  };

  const handleReserve = (plan) => {
    selectPlan(plan);
  };

  return (
    <React.Fragment>
      <Container>
        <SectionTitle
          title="Reservation"
          desc="Reservez une place pour le prochain événement."
        />

        <Row className="align-items-center">
          <Col md="6" xs="12" className="mt-4 pt-2">
            <Card
              className="rounded shadow border-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <CardBody>
                <h4 className="card-title">Reservation Ticket!</h4>
                <p>
                  <b className="text-primary">Info de paiement:</b> <br />
                  <b>PayPal:</b> tchindasapo@gmail.com <br />
                  {selectedPlan && (
                    <>
                      <b>
                        Plan: {selectedPlan.title} {selectedPlan.price} &euro;
                      </b>
                      <br />
                    </>
                  )}
                </p>

                <div className="custom-form bg-white">
                  <div id="message"></div>
                  <Alert
                    color="success"
                    isOpen={succeMsg}
                    toggle={() => {
                      setsucceMsg(false);
                    }}
                  >
                    Reservation effectuée avec succès!
                  </Alert>
                  <Form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                    <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Formule prise <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="pocket"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="paymentType"
                            id="paymentType"
                            type="select"
                            className="form-control ps-5"
                            placeholder="Moyen de Payement  :"
                            onChange={(e) => {
                              validation.handleChange(e);
                              const selected = pricings.find(
                                (plan) => plan.title === e.target.value,
                              );
                              handleReserve(selected);
                            }}
                            onBlur={validation.handleBlur}
                            value={selectedPlan.title}
                            invalid={
                              validation.touched.paymentType &&
                              validation.errors.paymentType
                            }
                          >
                            {pricings.map((plan, index) => (
                              <option value={plan.title} key={index}>
                                {plan.title} ({plan.price} &euro;)
                              </option>
                            ))}
                          </Input>
                          {validation.touched.paymentType &&
                            validation.errors.paymentType && (
                              <FormFeedback type="invalid">
                                {validation.errors.paymentType}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Nom et prénom <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Nom et prénom :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={
                              validation.touched.name && validation.errors.name
                            }
                          />
                          {validation.touched.name &&
                            validation.errors.name && (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Adresse Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="email2"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Adresse Email  :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Numéro de téléphone{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="phone"
                            id="phone"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Numéro de téléphone:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ''}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                            }
                          />
                          {validation.touched.phone &&
                            validation.errors.phone && (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Rôle <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user-check"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="role"
                            id="role"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Invité, Sponsor, Partenaire..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role || ''}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Commentaire:</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control ps-5"
                            placeholder="Your Message :"
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="text-center">
                        <div className="d-grid">
                          <Input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary text-center"
                            value="Aller au paiement"
                          />
                          <div id="simple-msg"></div>
                        </div>
                      </Col>
                      {onClickPaiement && (
                        <Paypalbuton
                          clientID={clientID}
                          total={selectedPlan.price}
                          validate={validate}
                        />
                      )}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="6" xs="12" className="mt-4 pt-2">
            <img src={classic} alt="" className="img-fluid rounded" />
          </Col>

          <Col md="12" xs="12" className="mt-4 pt-2">
            <div className="title-heading ms-lg-4">
              <h4 className="mb-4" data-aos="fade-up" data-aos-duration="1400">
                Contactez-nous
              </h4>
              <p className="text-muted">
                N'hésitez pas à nous contacter pour plus d'informations ou si
                vous avez des questions sur notre événement.
              </p>
              <ul
                className="contact-detail list-unstyled mt-4"
                data-aos="fade-up"
                data-aos-duration="1600"
              >
                <li className="d-flex mt-3">
                  <i className="uil uil-phone h5 me-3 mb-0"></i>
                  <div className="flex-1">
                    <h5 className="fw-bold">Téléphone</h5>
                    <p className="text-muted">
                      Appelez-nous au{' '}
                      <span className="text-primary">+4917687306753/ +4917664839400/ +4915737171295/ +4915906805841</span>
                    </p>
                  </div>
                </li>

                <li className="d-flex mt-3">
                  <i className="uil uil-envelope h5 me-3 mb-0"></i>
                  <div className="flex-1">
                    <h5 className="fw-bold">Email</h5>
                    <p className="text-muted">
                      Écrivez-nous à{' '}
                      <span className="text-primary">
                        kevinkouchougtchinda@gmail.com
                      </span>
                    </p>
                  </div>
                </li>

                <li className="d-flex mt-3">
                  <i className="uil uil-map-marker h5 me-3 mb-0"></i>
                  <div className="flex-1">
                    <h5 className="fw-bold">Adresse</h5>
                    <p className="text-muted">Adresse de l'événement : <span className="text-primary">
                    Oberhausen en Allemagne
                      </span> </p>
                  </div>
                </li>
              </ul>

              <div
                className="mt-4 pt-2"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <Link
                  to="#"
                  onClick={sendMail}
                  className="btn btn-primary mt-2 me-2"
                >
                  <i className="uil uil-envelope"></i> Envoyer un email
                </Link>
                <Link
                  to="#"
                  onClick={callNumber}
                  className="btn btn-outline-primary mt-2"
                >
                  <i className="uil uil-phone"></i> Appeler maintenant
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;
