import React, { Component } from "react";
import { Container, Row } from "reactstrap";

// Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import Pricing from "../../components/Shared/PricingBox";

class Price extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings: [
        {
          id: 1,
          title: "Pass l’émission",
          price: 15,
          duration: "Single",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          features: [],
        },
        {
          id: 2,
          title: "Pass After Party",
          price: 15,
          duration: "Single",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          features: [],
        },
        {
          id: 3,
          title: "Emission + After Party",
          price: 30,
          duration: "Combo",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          features: [],
        },
        {
          id: 4,
          title: "Standard (2 pers)",
          price: 150,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: true,
          features: [
            { title: "1 Henessy" },
            { title: "1 Soft" },
            { title: "1 Eau 33cl" },
          ],
        },
        {
          id: 5,
          title: "Standard (4 pers)",
          price: 300,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: true,
          features: [
            { title: "1 Chivas 18 ans" },
            { title: "2 Soft" },
            { title: "1 Belaire" },
            { title: "1 Eau 33cl" },
          ],
        },
        {
          id: 6,
          title: "Standard (8 pers)",
          price: 400,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: true,
          features: [
            { title: "2 Henessy" },
            { title: "2 Soft" },
            { title: "1 Bailey" },
            { title: "1 Eau 33cl" },
          ],
        },
        {
          id: 7,
          title: "Spécial Girl (6 filles)",
          price: 200,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: true,
          features: [
            { title: "2 Bailey" },
            { title: "1 Sekt" },
            { title: "1 Eau 33cl" },
          ],
        },
        {
          id: 8,
          title: "VIP (2 pers)",
          price: 180,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          isVIP: true,
          features: [
            { title: "1 Chivas 18 ans" },
            { title: "1 Soft" },
            { title: "1 Eau 33cl" },
            { title: "1 Redbull" },
          ],
        },
        {
          id: 9,
          title: "VIP (4 pers)",
          price: 400,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          isVIP: true,
          features: [
            { title: "1 Chivas 18 ans / Moet / Veuve Cliquot" },
            { title: "1 Soft" },
            { title: "1 Belaire" },
            { title: "1 Bailey" },
            { title: "1 Eau 33cl" },
            { title: "1 Redbull" },
            { title: "Ou (1 Ruinart, 1 Belaire, 1 Eau 33cl, 1 Redbull)" },
          ],
        },
        {
          id: 10,
          title: "VIP (6 pers)",
          price: 650,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          isVIP: true,
          features: [
            { title: "2 Chivas 18 ans / Moet / Veuve" },
            { title: "2 Soft" },
            { title: "1 Belaire" },
            { title: "1 Redbull" },
            { title: "2 Eau 33cl" },
          ],
        },
        {
          id: 11,
          title: "VIP (8-10 pers)",
          price: 1100,
          duration: "Group",
          buttonText: "Reserver",
          btnLink: "",
          isActive: false,
          isVIP: true,
          features: [
            { title: "3 Chivas 18 ans / Moet / Veuve Cliquot" },
            { title: "2 Ruinart" },
            { title: "2 Sekt" },
            { title: "2 Soft" },
            { title: "2 Redbull" },
            { title: "2 Eau 33cl" },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="mt-100 mt-60" id="pricing">
          <Container>
            {/* Section title */}
            <SectionTitle
              title="Grille des prix"
            />

            <Row id="pricing">
              <Pricing pricings={this.state.pricings}/>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Price;
