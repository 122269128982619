import React, { Component } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import {usePricingStore} from "../Shared/store"

const Pricing = ({pricings}) =>  {
  const selectPlan = usePricingStore((state) => state.selectPlan);
  const selectedPlan = usePricingStore((state) => state.selectedPlan)
  
 const  handleReserve = (plan) => {
    selectPlan(plan);
  };

    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {pricings.map((pricing, key) => (
            <Col
              lg={3}
              md={6}
              sm={12}
              className="mt-4 pt-2"
              key={key}
            >
              <Card
                className={
                  pricing.isActive
                    ? "card pricing pricing-primary business-rate shadow border-0 rounded"
                    : "card pricing pricing-primary business-rate shadow bg-light border-0 rounded"
                }
              >
                {pricing.isActive && (
                  <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                    <span className="text-center d-block shadow small h6">
                      Best
                    </span>
                  </div>
                )}
                {pricing.isVIP && (
                  <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                    <span className="text-center d-block shadow small h6">
                      VIP
                    </span>
                  </div>
                )}
                <CardBody>
                  <h6
                    className={
                      pricing.isActive
                        ? "title fw-bold text-uppercase text-primary mb-4"
                        : "title name fw-bold text-uppercase mb-4"
                    }
                  >
                    {pricing.title}
                  </h6>
                  <div className="d-flex mb-4">
                    <span className="h4 mb-0 mt-2">€</span>
                    <span className="price h1 mb-0">{pricing.price}</span>
                    <span className="h4 align-self-end mb-1">
                      /{pricing.duration}
                    </span>
                  </div>

                  <ul className="list-unstyled mb-0 ps-0">
                    {pricing.features.map((feature, key) => (
                      <li key={key} className="h6 text-muted mb-0">
                        <span className="icon h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        {feature.title}
                      </li>
                    ))}
                  </ul>
                  <a href="#contact" className="btn btn-primary mt-4 w-100" onClick={() => handleReserve(pricing)}>
                    {pricing.buttonText}
                  </a>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
}

export default Pricing;
