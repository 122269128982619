import React from "react";
import { Row, Col } from "reactstrap";
import SectionTitle from "./SectionTitle";

// Import Images
import img1 from "../../assets/images/smash-pass/sponsor/1.jpeg";
import img2 from "../../assets/images/smash-pass/sponsor/2.jpeg";
import img3 from "../../assets/images/smash-pass/sponsor/3.jpeg";
import img4 from "../../assets/images/smash-pass/sponsor/4.jpeg";
import img5 from "../../assets/images/smash-pass/sponsor/5.jpeg";
import img6 from "../../assets/images/smash-pass/sponsor/6.jpeg";
import img7 from "../../assets/images/smash-pass/sponsor/7.jpeg";
import img8 from "../../assets/images/smash-pass/sponsor/8.jpeg";

const Partners = () => {
  const listImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
  ];

  return (
    <React.Fragment>
      <SectionTitle title="Nos Sponsors" desc="Ils nous font confiance !" />
      <Row className="p-2 flex-wrap" id="partners">
        {listImg.map((item, key) => (
          <Col
            id={"partner" + (key + 1)}
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="text-center mb-4"
            key={key}
            data-aos=""
          >
            <img src={item} alt="" style={{ width: "80%", height: "auto" }} />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Partners;
