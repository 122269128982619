// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import about from '../../assets/images/smash-pass/logo-partner.jpeg';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="section border-top" id="service">
          <Container>
            <Card className="rounded shadow border-0 bg-light overflow-hidden">
              <Row className="g-0 align-items-center">
                <Col lg="6">
                  <img src={about} className="rounded img-fluid" alt="" />
                </Col>

                <Col lg="6">
                  <CardBody className="section-title p-md-5">
                    <h4 className="title mb-4">
                    ⛔️⛔️⛔️Smash or Pass 3 😍:{' '}
                    </h4>
                    <p className="text-muted para-desc mb-0">
                      Kamerdiaspo a le plaisir de vous annoncer que l’édition 3
                      de votre concept tant aimé Smash or Pass est prévu pour le
                      28 Septembre prochain à *Oberhausen en Allemagne (Finanz
                      Straße 6 46145 Oberhausen).<br /> Ce concept, qui dès sa première
                      édition a capté plus d’un et fait des échos, s’annonce
                      plus que palpitant à cette 3ème Édition 🤩 Ah oui la
                      famille, comme vous le savez déjà, ça sera un truc où vous
                      aurez de la bonne ambiance et surtout vous ferez de belles
                      rencontres 🥳😎.<br /> Ne vous laissez pas raconter, car tout est
                      prêt pour vous faire passer un moment particulier ce 28
                      Septembre 😁👌🏾 Contactes nous, pour venir vivre la magie
                      et surtout partage ce Flyer avec tes proches afin que
                      personne ne rate l’événement de l’année 🤌🏽 🔥🌪️ Nous t‘
                      attendons 🫵🏾
                    </p>
                    <div className="mt-4 pt-2">
                      <a href="#contact" className="btn btn-outline-primary">
                        Reserver <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
