import React, { Component } from "react";
import { Container } from "reactstrap";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";

// Import Components
import Section from "./Section";
import Features from "./Features";
import Process from "./Process";
import BlockContainer from "./BlockContainer";
import ModalVideoSection from "./ModalVideo";
import Shape from "./Shape";
import Testimonials from "./Testimonials";
import Partners from "../../components/Shared/Partners";
import Price from "./Price";
import Team from "./Team";
import ContactUs from "./ContactUs2";
import GoogleMap from "./GoogleMap";
import Popup from "../../components/Layout/popup";
import About from "./About";
import { SiteClient } from 'datocms-client';

const client = new SiteClient('84dee8dc4d0d03d1bdc0112665123a');

class SaasOnepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientID: "AVvz6ZX3sKltpEifxS21srEw4q6dPw1eFoijy4A2Ft-ZZiNHRwwQLnBQkD9ccYqnkW64i2SQFuhLZrUh",
    };
  }

  componentDidMount() {
    // Initialize Aos Animation
    AOS.init();

    // Refresh Aos Animation whenever components will render
    AOS.refresh();

    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);

    // Set AOS attributes to section title and desc
    const setAOSAttributes = (elements, duration) => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute("data-aos", "fade-up");
        elements[i].setAttribute("data-aos-duration", duration);
      }
    };

    client.items.all({ filter: { type: 'paypal' } })
      .then((clientID) => {
        this.setState({ clientID });
      });

    setAOSAttributes(document.getElementsByName("sectiontitle"), "1200");
    setAOSAttributes(document.getElementsByName("sectiondesc"), "1000");
    setAOSAttributes(document.getElementsByName("clientsreview"), "1800");
    setAOSAttributes(document.getElementsByName("featurebox"), "1800");

    for (let k = 1; k <= 6; k++) {
      document.getElementById("partner" + k)?.setAttribute("data-aos", "fade-up");
      document.getElementById("partner" + k)?.setAttribute("data-aos-duration", "1800");
    }

    setAOSAttributes(document.getElementsByName("pricing"), "1400");
    setAOSAttributes(document.getElementsByName("team"), "1200");
    setAOSAttributes(document.getElementsByName("footercolumn"), "1400");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };

  render() {
    const { clientID } = this.state;
    return (
      <React.Fragment>
        {/* section */}
        <Section />

        {/* About */}
        <About />

        {/* <section className="section pb-0" id="service"> */}
        {/* features */}
        {/* <Features /> */}

        {/* process */}
        {/* <Process /> */}

        {/* block container */}
        {/* <BlockContainer /> */}

        {/* modal Video */}
        <ModalVideoSection />
        {/* </section> */}

        {/* shape */}
        <Shape />

        <section className="section bg-light" id="testimonial">
          {/* testimonials */}
          {/* <Testimonials /> */}

          <Container className="pt-0">
            {/* partners */}
            <Partners />
          </Container>
        </section>

        {/* pricing */}
        <Price />

        {/* team members */}
        {/* <Team /> */}

        <section className="section pb-0" id="contact">
          {/* contact us */}
          <ContactUs clientID={"AVvz6ZX3sKltpEifxS21srEw4q6dPw1eFoijy4A2Ft-ZZiNHRwwQLnBQkD9ccYqnkW64i2SQFuhLZrUh"} />

          {/* google map */}
          {/* <GoogleMap /> */}
        </section>

        {/* Popup */}
        {/* <Popup /> */}
      </React.Fragment>
    );
  }
}

export default SaasOnepage;
